@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  margin: 0;
  color: #1b1a20;
  font-family: 'Roboto', sans-serif; }

.header {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ddd; }
  .header .subtitle {
    text-transform: uppercase;
    color: red;
    font-weight: 900; }
  .header .contact {
    margin-top: 5px; }
    .header .contact a {
      color: red;
      font-weight: 900;
      text-decoration: none; }
  .header img {
    max-height: 60px;
    max-width: 65%; }

.footer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ddd; }
  .footer div:not(:last-child) {
    margin-bottom: 5px; }
  .footer a {
    font-size: 0.85rem;
    color: #1b1a20;
    text-decoration: none; }

main {
  display: flex;
  padding: 10px 2%;
  max-width: 1200px;
  margin: 10px auto 0 auto; }

@media only screen and (max-width: 1024px) {
  body .filters {
    width: 20%; }
  body .shelf-container {
    width: 80%; }
    body .shelf-container .shelf-item {
      width: 33.33%; } }

@media only screen and (max-width: 640px) {
  body .filters {
    width: 25%; }
  body .shelf-container {
    width: 75%; }
    body .shelf-container .shelf-item {
      width: 50%;
      padding: 10px; }
      body .shelf-container .shelf-item__title {
        margin-top: 5px;
        padding: 0; }
  body .float-cart {
    width: 100%;
    right: -100%; }
    body .float-cart--open {
      right: 0; }
    body .float-cart__close-btn {
      left: 0px;
      z-index: 2;
      background-color: #1b1a20; }
    body .float-cart__header {
      padding: 25px 0; } }

@media only screen and (max-width: 460px) {
  body main {
    display: flex;
    flex-wrap: wrap;
    padding: 2%; }
  body .filters {
    width: 100%;
    margin-right: 0;
    text-align: center; }
    body .filters .title {
      margin-bottom: 15px; }
  body .shelf-container-header .products-found {
    width: 100%;
    text-align: center;
    margin: 10px 0; }
  body .shelf-container-header .sort {
    width: 100%;
    text-align: center; }
  body .shelf-container {
    width: 100%; }
    body .shelf-container .shelf-item {
      width: 50%; } }

.main-whatsapp-link {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 10px 5px 10px;
  font-size: 45px;
  background-color: #26cd65;
  background-size: 50%;
  cursor: pointer;
  opacity: 85%;
  -webkit-opacity: 85%;
  border-bottom-right-radius: 20%; }
  .main-whatsapp-link a {
    color: white; }

