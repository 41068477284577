@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  color: #1b1a20;
  font-family: 'Roboto', sans-serif;
}

.header {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ddd;

  .subtitle {
    text-transform: uppercase;
    color: red;
    font-weight: 900;
  }

  .contact {
    margin-top: 5px;

    a {
      color: red;
      font-weight: 900;
      text-decoration: none;
    }
  }

  img {
    max-height: 60px;
    max-width: 65%;
  }
}

.footer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ddd;

  div:not(:last-child) {
    margin-bottom: 5px;
  }

  a {
    font-size: 0.85rem;
    color: #1b1a20;
    text-decoration: none;
  }
}

main {
  display: flex;
  padding: 10px 2%;
  max-width: 1200px;
  margin: 10px auto 0 auto;
}

@media only screen and (max-width: 1024px) {
  body {
    .filters {
      width: 20%;
    }

    .shelf-container {
      width: 80%;

      .shelf-item {
        width: 33.33%;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  body {
    .filters {
      width: 25%;
    }

    .shelf-container {
      width: 75%;

      .shelf-item {
        width: 50%;
        padding: 10px;

        &__title {
          margin-top: 5px;
          padding: 0;
        }
      }
    }

    .float-cart {
      width: 100%;
      right: -100%;

      &--open {
        right: 0;
      }

      &__close-btn {
        left: 0px;
        z-index: 2;
        background-color: #1b1a20;
      }

      &__header {
        padding: 25px 0;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  body {
    main {
      display: flex;
      flex-wrap: wrap;
      padding: 2%;
    }

    .filters {
      width: 100%;
      margin-right: 0;
      text-align: center;

      .title {
        margin-bottom: 15px;
      }
    }

    .shelf-container-header {
      .products-found {
        width: 100%;
        text-align: center;
        margin: 10px 0;
      }

      .sort {
        width: 100%;
        text-align: center;
      }
    }

    .shelf-container {
      width: 100%;

      .shelf-item {
        width: 50%;
      }
    }
  }
}

.main-whatsapp-link {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px 10px 5px 10px;
  font-size: 45px;
  background-color: #26cd65;
  background-size: 50%;
  cursor: pointer;
  opacity: 85%;
  -webkit-opacity: 85%;
  border-bottom-right-radius: 20%;

  a {
    color: white;
  }
}